@tailwind base;
@tailwind components;
@tailwind utilities;
@import '@fontsource/source-sans-3';
@import '@fontsource/source-sans-3/200.css';
@import '@fontsource/source-sans-3/300.css';
@import '@fontsource/source-sans-3/400.css';
@import '@fontsource/source-sans-3/500.css';
@import '@fontsource/source-sans-3/600.css';
@import '@fontsource/source-sans-3/700.css';
@import '@fontsource/source-sans-3/800.css';


html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: 'Source Sans 3', sans-serif;
}

#__next {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: white !important;
    background: white !important;
    color: #323438 !important;
  }
  body {
    color: #323438;
    background: white;
  }
}

.pagination .pagination-item button {
  @apply font-[600];
  @apply rounded-md;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply w-8;
  @apply h-8;
  @apply lg:w-10;
  @apply lg:h-10;
  @apply hover:bg-primary-pure;
  @apply bg-white;
  @apply text-neutral-pure600;
  @apply hover:text-white;
  @apply disabled:hover:bg-neutral-pure100;
  @apply disabled:hover:text-neutral-pure600;
}

.pagination .pagination-item button.current {
  @apply bg-primary-pure;
  @apply text-white;
}

/* styles.css or globals.css */

/* AGUARDANDO_PAGAMENTO */
.custom-bg-AGUARDANDO_PAGAMENTO {
  background-color: #9EA3A5;
}

/* PAGO */
.custom-bg-PAGO {
  background-color: #AFCCE3;
}

/* ENVIADO */
.custom-bg-ENVIADO {
  background-color: #F4E18C;
}

/* ENTREGUE */
.custom-bg-ENTREGUE {
  background-color: #AFD49E;
}

/* CANCELADO */
.custom-bg-CANCELADO {
  background-color: #F8BCAE;
}

/* styles.css or globals.css */

/* AGUARDANDO_PAGAMENTO */
.divider-AGUARDANDO_PAGAMENTO::before,
.divider-AGUARDANDO_PAGAMENTO::after {
  background-color: rgba(109, 113, 115, 1);
}

/* PAGO */
.divider-PAGO::before,
.divider-PAGO::after {
  background-color: rgba(42, 150, 193, 1);
}

/* ENVIADO */
.divider-ENVIADO::before,
.divider-ENVIADO::after {
  background-color: rgba(235, 205, 12, 1);
}

/* ENTREGUE */
.divider-ENTREGUE::before,
.divider-ENTREGUE::after {
  background-color: rgba(72, 183, 72, 1);
}

/* FINISHED */
.divider-FINISHED::before,
.divider-FINISHED::after {
  background-color: rgba(72, 183, 72, 1);
}

/* CANCELADO */
.divider-CANCELADO::before,
.divider-CANCELADO::after {
  background-color: rgba(249, 95, 82, 1);
}

/* Width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(50, 52, 56, 0.10);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(50, 52, 56,0.1);
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(50, 52, 56,0.3);
}